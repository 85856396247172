$bs-datetimepicker-timepicker-font-size: $font-size-base !default;
$bs-datetimepicker-active-bg: $primary-second !default;
$bs-datetimepicker-active-color: $white !default;
$bs-datetimepicker-border-radius: $border-radius !default;
$bs-datetimepicker-btn-hover-bg: $gray-200 !default;
$bs-datetimepicker-disabled-color: rgba(0, 0, 0, 0.2) !default;
$bs-datetimepicker-alternate-color: $gray-600 !default;
$bs-datetimepicker-secondary-border-color: #ccc !default;
$bs-datetimepicker-secondary-border-color-rgba: rgba(0, 0, 0, 0.2) !default;
$bs-datetimepicker-primary-border-color: white !default;
$bs-datetimepicker-text-shadow: none !default;
$bs-datetimepicker-buttons-color: $primary-second;
$bs-datetimepicker-buttons-color-dark: darken($bs-datetimepicker-buttons-color, 10%);

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

body.tempusdominus-bootstrap-datetimepicker-widget-day-click,
body.tempusdominus-bootstrap-datetimepicker-widget-day-click * {
  cursor: pointer !important;
}

body.tempusdominus-bootstrap-datetimepicker-widget-day-click {
  position: relative !important;
}

.tempusdominus-bootstrap-datetimepicker-widget-day-click-glass-panel {
  position: absolute;
  z-index: 999999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer !important;
}

.bootstrap-datetimepicker-widget .datepicker-days {
  tbody td {
    cursor: pointer;
  }
}

.bootstrap-datetimepicker-widget {
  * {
    font-family: inherit !important;
    font-size: $h6-font-size;
  }

  list-style: none;

  &.dropdown-menu {
    display: block;
    margin: 2px 0;
    padding: 4px;
    width: 14rem;

    &.tempusdominus-bootstrap-datetimepicker-widget-with-feather-icons {
      width: 16rem;
    }

    &.tempusdominus-bootstrap-datetimepicker-widget-with-calendar-weeks {
      width: 16rem;

      &.tempusdominus-bootstrap-datetimepicker-widget-with-feather-icons {
        width: 17rem;
      }
    }

    &.wider {
      width: 16rem;
    }
  }

  .timepicker-hour,
  .timepicker-minute,
  .timepicker-second {
    font-weight: bold;
    font-size: $bs-datetimepicker-timepicker-font-size;
  }

  .btn[data-action="togglePeriod"] {
    text-align: center;
    width: 38px;
    height: 38px;
  }

  .btn[data-action="incrementHours"]::after {
    @extend .sr-only;
    content: "Increment Hours";
  }

  .btn[data-action="incrementMinutes"]::after {
    @extend .sr-only;
    content: "Increment Minutes";
  }

  .btn[data-action="decrementHours"]::after {
    @extend .sr-only;
    content: "Decrement Hours";
  }

  .btn[data-action="decrementMinutes"]::after {
    @extend .sr-only;
    content: "Decrement Minutes";
  }

  .btn[data-action="showHours"]::after {
    @extend .sr-only;
    content: "Show Hours";
  }

  .btn[data-action="showMinutes"]::after {
    @extend .sr-only;
    content: "Show Minutes";
  }

  .btn[data-action="togglePeriod"]::after {
    @extend .sr-only;
    content: "Toggle AM/PM";
  }

  .btn[data-action="clear"]::after {
    @extend .sr-only;
    content: "Clear the picker";
  }

  .btn[data-action="today"]::after {
    @extend .sr-only;
    content: "Set the date to today";
  }

  .picker-switch {
    font-size: $h4-font-size;
    text-align: center;
    &::after {
      @extend .sr-only;
      content: "Toggle Date and Time Screens";
    }
  }

  .grid {
    gap: 0;
  }

  .btn {
    padding: 0;
  }

  table {
    width: 100%;
    margin: 0;

    td,
    th {
      padding: 0;
      text-align: center;
      border: none;
    }

    th {
      font-weight: 600;

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $bs-datetimepicker-disabled-color;
        cursor: not-allowed;
      }

      &.prev::after {
        @extend .sr-only;
        content: "Previous Month";
      }

      &.next::after {
        @extend .sr-only;
        content: "Next Month";
      }
    }

    thead tr:last-child:not(:first-child) th {
      @extend .border-bottom;
      @extend .border-light;
      @extend .py-2;
    }

    & td {
      &.cw {
        font-size: 0.8em;
        height: 20px;
        line-height: 20px;
        color: $bs-datetimepicker-alternate-color;
        cursor: default;
      }
    }
  }

  --action-size: 54px;
  --columns: 4;
  --space: 2px;

  [data-action] {
    cursor: pointer;
    border-radius: $bs-datetimepicker-border-radius;
    z-index: 1;
    &:not(.btn-primary):not(.active):hover {
      background: $bs-datetimepicker-btn-hover-bg;
    }
    &.active {
      background-color: $bs-datetimepicker-active-bg;
      color: $bs-datetimepicker-active-color;
      text-shadow: $bs-datetimepicker-text-shadow;
    }
    &.old,
    &.new {
      color: $bs-datetimepicker-alternate-color;
    }
    &.disabled,
    &.disabled:hover {
      background: none;
      color: $bs-datetimepicker-disabled-color;
      cursor: not-allowed;
    }
  }
  a[data-action],
  span[data-action] {
    display: inline-block;
    margin: var(--space);
    height: var(--action-size);
    line-height: var(--action-size);
    width: var(--action-size);
    box-shadow: none;
  }

  .accordion-toggle [data-action] {
    display: block;
    width: 100%;
  }

  .datepicker-months,
  .datepicker-years,
  .datepicker-decades {
    td {
      max-width: calc((var(--action-size) + var(--space) * 2) * var(--columns));
    }
  }

  .timepicker-picker,
  .timepicker-hours,
  .timepicker-minutes,
  .timepicker-seconds {
    &,
    & table {
      height: 100%;
    }
  }

  --day-size: 40px;

  .day {
    min-width: var(--day-size);
    height: var(--day-size);
    line-height: var(--day-size);
    position: relative;
    background: none !important;
    font-weight: 500;
    &::after {
      content: "";
      width: 26px;
      height: 26px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      translate: -50% -50%;
    }
    &:hover::after {
      background-color: $bs-datetimepicker-btn-hover-bg;
    }
    &.active::after {
      background-color: $bs-datetimepicker-active-bg;
    }
    &.disabled {
      color: $bs-datetimepicker-disabled-color;
      &::after {
        background-color: $bs-datetimepicker-disabled-color;
        height: 1px;
        width: 20px;
        border-radius: 0;
        rotate: -45deg;
      }
    }
    &.today {
      &:before {
        content: "";
        display: inline-block;
        border: solid transparent;
        border-width: 0 0 7px 7px;
        border-bottom-color: $bs-datetimepicker-active-bg;
        border-top-color: $bs-datetimepicker-secondary-border-color-rgba;
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
      &.active:before {
        border-bottom-color: #fff;
      }
    }
  }
  .hour,
  .minute,
  .second {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  &.usetwentyfour {
    td.hour {
      height: 27px;
      line-height: 27px;
    }
  }

  .timepicker .timepicker-picker a.btn {
    color: $bs-datetimepicker-buttons-color;
    &:hover {
      color: $bs-datetimepicker-buttons-color-dark;
    }
  }

  &.bootstrap-datetimepicker-widget-readonly {
    table td.day,
    table td.hour,
    table td.minute,
    table td.second,
    table td [data-action="incrementHours"],
    table td [data-action="incrementMinutes"],
    table td [data-action="incrementSeconds"],
    table td [data-action="decrementHours"],
    table td [data-action="decrementMinutes"],
    table td [data-action="decrementSeconds"],
    table td [data-action="showHours"],
    table td [data-action="showMinutes"],
    table td [data-action="showSeconds"],
    table td [data-action="togglePeriod"] {
      pointer-events: none;
      cursor: default;

      &:hover {
        background: none;
      }
    }
  }
}

.input-group [data-toggle="datetimepicker"] {
  cursor: pointer;
}
