// --- Options --- //

$enable-shadows: true;
$enable-negative-margins: true;
$enable-cssgrid: true;
// $enable-validation-icons: false;

// --- Colors --- //

$primary: #64bc1c;
$primary-second: #0052b4;
$secondary-bg: #e9ecef;
$dark: #141414;
$light: #fafafc;
$gray-medium: #63636a;
$gray-light: #bababa;
$gray-lightest: #ebebeb;
$gray-lightest: #ebebeb;
$info: #396c93;
$success: #399352;
$warning: #f09540;
$danger: #d6513a;
$white: white;
$gradient: linear-gradient(to right, $primary, $primary-second);
$focus-ring-color: var(--bs-focus-ring-color);

$link-color: $primary-second;
$component-active-color: $primary-second;
$component-active-bg: $primary;
$border-color: $gray-light;
$body-color: $dark;
$text-muted: $gray-light;
$hr-color: $gray-lightest;
$hr-opacity: 1;

// Validation icons
$form-feedback-icon-valid-color: $primary;
$form-feedback-icon-invalid-color: $danger;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21' width='21' height='21' fill='none'><path d='M9.24507 14.2326C9.34125 14.3179 9.48607 14.3197 9.58436 14.2346C11.728 12.3747 13.6821 10.424 16.0136 8.45553C16.2148 8.28563 16.3347 8.04818 16.3514 7.78632C16.4029 6.97249 15.4338 6.43956 14.7752 6.98433C12.7804 8.63178 11.1219 10.1261 9.57499 11.6705C9.27099 11.3668 8.86431 10.9324 8.36248 10.3749C7.97452 9.94402 7.27433 9.86947 6.80333 10.3347C6.38963 10.7431 6.36989 11.3986 6.75801 11.8273C7.69478 12.8612 8.2829 13.3772 9.24507 14.2326Z' fill='#{$form-feedback-icon-valid-color}'/><path d='M4.21086 18.8559C7.49622 21.409 12.4669 21.6098 15.9091 19.2724C18.8667 17.2641 20.6015 13.4025 20.2877 9.82294C20.1006 4.50213 16.6433 1.18935 10.9569 0.930491C9.28443 0.851229 7.65911 1.26493 6.2261 2.10646C3.83233 3.23319 1.85298 5.26925 0.959794 7.76614C-0.441632 11.6838 0.925472 16.3028 4.21086 18.8559ZM3.06919 6.30242C4.67608 3.29638 7.54247 1.28708 10.9335 1.44352C16.5576 1.6997 19.7831 4.92102 19.7831 10.2817C19.7831 15.1549 15.8185 19.1196 10.9452 19.1196C4.23054 19.1196 0.0769612 11.9006 3.06919 6.30242Z' fill='#{$form-feedback-icon-valid-color}'/></svg>");
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
$form-valid-border-color: $dark;

@import "functions";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "bootstrap_mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

$theme-colors: map-merge(
  $theme-colors,
  (
    primary: $primary,
    primary-second: $primary-second,
    secondary-bg: $secondary-bg,
    dark: $dark,
    light: $light,
    gray-medium: $gray-medium,
    gray-light: $gray-light,
    gray-lightest: $gray-lightest,
    warning: $warning,
    danger: $danger,
    "white": $white,
    "transparent": transparent
  )
);

// --- Text --- //

$font-size-root: 16px;
$font-family-sans-serif: join(Roboto, $font-family-sans-serif);

$h1-font-size: to-rem(52);
$h2-font-size: to-rem(38);
$h3-font-size: to-rem(26);
$h4-font-size: to-rem(18);
$h5-font-size: $font-size-base;
$h6-font-size: to-rem(14);
$h7-font-size: to-rem(12);
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
);

$headings-margin-bottom: 0;
$headings-font-family: "Epilogue";
$headings-font-weight: 600;
$headings-line-height: 1.5;

// --- Layout --- //

$grid-range: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
$grid-gutter-width: to-rem(20);
$container-padding-x: to-rem(40);

$grid-breakpoints: (
  xs: 0,
  xsm: 425px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1320px,
  xxl: 1600px
);

$container-max-widths: map-merge(
  $container-max-widths,
  (
    xl: 1280px,
    xxl: 1400px
  )
);

$spacers: (
  0: 0,
  0\.5: to-rem(2),
  1: to-rem(4),
  2: to-rem(7),
  3: to-rem(12),
  4: to-rem(17),
  5: to-rem(22),
  10: to-rem(10),
  15: to-rem(15),
  20: to-rem(20),
  25: to-rem(25),
  30: to-rem(30),
  35: to-rem(35),
  40: to-rem(40),
  45: to-rem(45),
  50: to-rem(50),
  55: to-rem(55),
  60: to-rem(60),
  70: to-rem(70),
  80: to-rem(80),
  90: to-rem(90),
  100: to-rem(100)
);

$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);

$border-radius-sm: to-rem(3);
$border-radius: to-rem(10);
$border-radius-lg: to-rem(15);
$border-radius-xl: to-rem(20);

$box-shadow: 0 0 11px rgb(0 0 0 / 9%);
$box-shadow-lg: 0 1px 24px rgb(0 0 0 / 10%);

// --- Button --- //

$btn-padding-y: map-get($spacers, 3);
$btn-padding-x: map-get($spacers, 40);
$btn-border-radius: map-get($spacers, 30);
$btn-font-weight: bold;
$btn-line-height: 1.2;

$btn-padding-y-sm: map-get($spacers, 2);
$btn-padding-x-sm: map-get($spacers, 3);
$btn-font-size-sm: $h5-font-size;

// --- Dropdown --- //

$dropdown-min-width: to-rem(250);
$dropdown-padding-y: map-get($spacers, 3);
$dropdown-spacer: map-get($spacers, 1);
$dropdown-border-radius: $border-radius-lg;
$dropdown-border-width: 0;
$dropdown-box-shadow: $box-shadow;
$dropdown-font-size: $h6-font-size;
$dropdown-divider-bg: $gray-lightest;
$dropdown-divider-margin-y: map-get($spacers, 10);

$dropdown-item-padding-y: map-get($spacers, 2);

$dropdown-link-hover-bg: $gray-lightest;
$dropdown-link-active-color: white;
$dropdown-link-active-bg: $primary-second;

// --- Nav --- //

$nav-link-padding-y: map-get($spacers, 1);
$nav-link-padding-x: map-get($spacers, 15);
$nav-link-font-size: $h4-font-size;
$nav-link-font-weight: $font-weight-semibold;
$nav-link-color: $gray-light;
$nav-link-active-color: $dark;
$nav-link-hover-color: $dark;

$nav-pills-border-radius: $border-radius-pill;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $dark;

// --- Modal --- //

$modal-backdrop-bg: $dark;
$modal-backdrop-opacity: 0.85;

// --- Form --- //

// Input
$focus-ring-color: rgba($primary-second, $focus-ring-opacity);

$input-padding-y: map-get($spacers, 10);
$input-padding-x: map-get($spacers, 10);
$input-font-size: $h5-font-size;
$input-line-height: 1.2;
$input-font-weight: 400;

$input-border-radius: $border-radius;
$input-border-color: $gray-light;
$input-placeholder-color: $gray-light;

$input-focus-border-color: $primary-second;
// $input-focus-box-shadow: 0 0 0 0.25rem rgba($primary-second, 0.25);
// $input-focus-box-shadow: 0 0 0 0.25rem var(--bs-focus-ring-color);

// Select
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-line-height: $input-line-height;
$form-select-font-weight: $input-font-weight;

$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;

$form-select-focus-border-color: $input-focus-border-color;
// $form-select-focus-box-shadow: $input-focus-box-shadow;

// Check/Radio
$form-check-input-width: to-rem(18);
$form-check-margin-bottom: map-get($spacers, 20);
$form-check-padding-start: calc($form-check-input-width + map-get($spacers, 3));
$form-check-input-border-radius: map-get($spacers, 1);
$form-check-label-cursor: pointer;
$form-check-input-bg: transparent;
$form-check-input-focus-border: $primary-second;
// $form-check-input-focus-box-shadow: $input-focus-box-shadow;
$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $primary-second;
$form-check-input-checked-border-color: $primary-second;
$form-check-input-indeterminate-bg-color: $form-check-input-checked-bg-color;
$form-check-input-indeterminate-border-color: $form-check-input-checked-border-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$primary-second}'/></svg>");

// Switch
$form-switch-width: to-rem(38);
$form-switch-padding-start: $form-switch-width + map-get($spacers, 10);

$form-switch-color: $white;
$form-switch-focus-color: $white;
$form-switch-checked-color: $white;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");

// Label
$form-label-margin-bottom: map-get($spacers, 2);
$form-label-font-size: $h5-font-size;
$form-label-font-weight: $font-weight-semibold;
$form-label-color: $dark;

// Badge
$badge-font-size: to-rem(11);
$badge-padding-y: to-rem(2);
$badge-padding-x: to-rem(2);
$badge-border-radius: $border-radius-sm;

//Accordion
$accordion-button-active-color: $dark;
$accordion-border-color: transparent;
$accordion-button-active-bg: $white;
$accordion-button-focus-box-shadow: transparent;
$accordion-button-padding-y: map-get($spacers, 30);
$accordion-button-padding-x: map-get($spacers, 30);
$accordion-body-padding-y: 0 map-get($spacers, 30);
$accordion-body-padding-x: map-get($spacers, 30);

//Range
$form-range-track-height: to-rem(3);

$form-range-thumb-width: to-rem(19);
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $primary-second;
$form-range-thumb-active-bg: $primary-second;
$form-range-thumb-box-shadow: 0 0 0 1px $white, 0 0 0 2px $primary-second;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $white, 0 0 0 4px rgba($primary-second, 0.5);

//Table
$table-hover-bg-factor: 0.1;
$table-hover-bg: rgba($primary-second, 0.1);

$modal-backdrop-bg: rgba($dark, 0.25);
$modal-backdrop-opacity: 1;
$offcanvas-backdrop-bg: $modal-backdrop-bg;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity;

// --- Utilities --- //

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          responsive: true,
          values: $position-values
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "font-size":
      map-merge(
        map-get($utilities, "font-size"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "height":
      map-merge(
        map-get($utilities, "height"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "viewport-height":
      map-merge(
        map-get($utilities, "viewport-height"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "rounded":
      map-merge(
        map-get($utilities, "rounded"),
        (
          responsive: true
        )
      )
  )
);
$utilities: map-merge(
  $utilities,
  (
    "rounded-top":
      map-merge(
        map-get($utilities, "rounded-top"),
        (
          responsive: true
        )
      )
  )
);
$utilities: map-merge(
  $utilities,
  (
    "rounded-bottom":
      map-merge(
        map-get($utilities, "rounded-bottom"),
        (
          responsive: true
        )
      )
  )
);
$utilities: map-merge(
  $utilities,
  (
    "rounded-start":
      map-merge(
        map-get($utilities, "rounded-start"),
        (
          responsive: true
        )
      )
  )
);
$utilities: map-merge(
  $utilities,
  (
    "rounded-end":
      map-merge(
        map-get($utilities, "rounded-end"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border":
      map-merge(
        map-get($utilities, "border"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-start":
      map-merge(
        map-get($utilities, "border-start"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-end":
      map-merge(
        map-get($utilities, "border-end"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-top":
      map-merge(
        map-get($utilities, "border-top"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-bottom":
      map-merge(
        map-get($utilities, "border-bottom"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-color":
      map-merge(
        map-get($utilities, "border-color"),
        (
          property: (
            --#{$prefix}border-color,
            border-color
          ),
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "gutter-x": (
      property: --bs-gutter-x,
      class: gx,
      responsive: true,
      values: $gutters
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "gutter-y": (
      property: --bs-gutter-y,
      class: gy,
      responsive: true,
      values: $gutters
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "font-weight":
      map-merge(
        map-get($utilities, "font-weight"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "font-weight"), "values"),
              (
                medium: $font-weight-medium
              )
            )
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          state: hover focus checked active
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-color":
      map-merge(
        map-get($utilities, "border-color"),
        (
          state: hover focus checked active
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-color":
      map-merge(
        map-get($utilities, "border-color"),
        (
          state: hover focus checked active
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          state: hover focus checked active
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "opacity":
      map-merge(
        map-get($utilities, "opacity"),
        (
          state: hover focus,
          values:
            map-merge(
              map-get(map-get($utilities, "opacity"), "values"),
              (
                0: 0,
                5: 0.05
              )
            )
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "bg-opacity":
      map-merge(
        map-get($utilities, "bg-opacity"),
        (
          state: hover focus,
          values:
            map-merge(
              map-get(map-get($utilities, "bg-opacity"), "values"),
              (
                0: 0,
                5: 0.05
              )
            )
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border-opacity":
      map-merge(
        map-get($utilities, "border-opacity"),
        (
          state: hover focus,
          values:
            map-merge(
              map-get(map-get($utilities, "border-opacity"), "values"),
              (
                0: 0,
                5: 0.05
              )
            )
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "text-opacity":
      map-merge(
        map-get($utilities, "text-opacity"),
        (
          state: hover focus,
          values:
            map-merge(
              map-get(map-get($utilities, "text-opacity"), "values"),
              (
                0: 0,
                5: 0.05
              )
            )
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "grid-columns": (
      property: --bs-columns,
      class: grid-cols,
      responsive: true,
      values: $grid-range
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "grid-rows": (
      property: --bs-rows,
      class: grid-rows,
      responsive: true,
      values: $grid-range
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "gap":
      map-merge(
        map-get($utilities, "gap"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "gap-x": (
      property: column-gap,
      class: gap-x,
      responsive: true,
      values: $spacers
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "gap-y": (
      property: row-gap,
      class: gap-y,
      responsive: true,
      values: $spacers
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      class: cursor,
      values: auto pointer grab
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "pointer-events": (
      property: pointer-events,
      class: pointer-events,
      values: auto none
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "fill": (
      property: fill,
      class: fill,
      state: hover,
      values: (
        current: currentColor,
        gradient: url(#gradient)
      )
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "object-fit": (
      property: object-fit,
      class: object-fit,
      values: cover contain fill scale-down
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "rotate": (
      property: rotate,
      class: rotate,
      values: (
        0: 0deg,
        45: 45deg,
        90: 90deg,
        135: 135deg,
        180: 180deg,
        270: 270deg
      )
    )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "top":
      map-merge(
        map-get($utilities, "top"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "bottom":
      map-merge(
        map-get($utilities, "bottom"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "start":
      map-merge(
        map-get($utilities, "start"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "end":
      map-merge(
        map-get($utilities, "end"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "z-index":
      map-merge(
        map-get($utilities, "z-index"),
        (
          responsive: true,
          state: hover focus
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "translate-middle":
      map-merge(
        map-get($utilities, "translate-middle"),
        (
          responsive: true
        )
      )
  )
);

$utilities: map-merge(
  $utilities,
  (
    "translate-0":
      map-merge(
        map-get($utilities, "translate-middle"),
        (
          class: translate-0,
          responsive: true,
          values: (
            null: translate(0, 0),
            x: translateX(0),
            y: translateY(0)
          )
        )
      )
  )
);
