.visibility-visible
  visibility: visible!important

.btn-primary
  --bs-btn-color: var(--bs-white)
  --bs-btn-hover-color: var(--bs-white)
  --bs-btn-disabled-color: var(--bs-white)
  --bs-btn-active-color: var(--bs-white)

.btn-icon
  +button-size(map-get($spacers, 2), map-get($spacers, 2), $font-size-base, map-get($spacers, 5))

.btn-ghost
  +button-variant(transparent, transparent, $dark, $gray-lightest, transparent)
  &:active
    background: lighten($primary, 50%)!important
  box-shadow: none!important

.placeholder-white::placeholder
  color: var(--bs-white)

.form-check-label
  font-weight: bold

.form-check-input[type="radio"]:checked
  background-color: white

.form-switch .form-check-input
  height: to-rem(18)

.form-switch .form-check-input:not(:checked)
  background-color: $gray-light
.form-switch .form-check-input:not(:checked):not(:focus)
  border-color: $gray-light

.form-control.is-invalid
  background-image: none
  padding-right: to-rem(10)
.invalid-feedback
  --bs-bg-opacity: .05

body
  > .dropdown-menu
    +media-breakpoint-down(sm)
      --bs-dropdown-zindex: #{$zindex-popover}

.dropdown-menu
  box-shadow: var(--bs-dropdown-box-shadow)

.transition
  transition: $transition-base
  &-none
    transition: none

.react-tel-input
  .form-control
    @extend .form-control
    @extend .ps-60
    padding-left: to-rem(60)
  .form-control:focus
    @extend .form-control
  .country-list
    @extend .dropdown-menu
    @extend .show
  .country-list .country
    @extend .dropdown-item
    padding-left: to-rem(46)
    & + .country
      margin-top: map-get($spacers, 10)
    &.active .dial-code
      @extend .text-white
      @extend .text-opacity-50

  .country-list .flag
    top: 0

  .flag-dropdown,
  .selected-flag,
  .selected-flag:before
    border-radius: to-rem(9) 0 0 to-rem(9)

  .selected-flag:before
    top: 0
    bottom: 0

  .selected-flag:focus:before,
  .selected-flag.open:before
    border-color: $input-focus-border-color
    box-shadow: $input-focus-box-shadow

.modal-title
  font-size: inherit
  font-weight: inherit

.overflow-y-scroll
  overflow-y: scroll!important
.overflow-x-scroll
  overflow-x: scroll!important

.flex-basis-0
  flex-basis: 0!important

.sticky-top
  position: sticky
  z-index: 1020
  top: var(--sticky-top, 0)

.sticky-bottom
  position: sticky
  z-index: 1020
  bottom: var(--sticky-bottom, 0)

.sticky-start
  position: sticky
  z-index: 1020
  left: var(--sticky-start, 0)

.sticky-end
  position: sticky
  z-index: 1020
  right: var(--sticky-end, 0)

.z-index-overlay
  z-index: 10000!important

.h-0
  height: 0!important

.min-h-100
  min-height: 100%

.min-h-0
  min-height: 0

.min-w-0
  min-width: 0

.w-max-content
  width: max-content

.max-vw-100
  max-width: 100vw

.max-vh-100
  max-height: 100vw

.w-max-content
  width: max-content

.bg-current
  background-color: currentColor!important

.border-current
  --bs-border-color: currentColor

.text-current
  color: currentColor!important
  &:hover
    color: currentColor!important

.aspect-ratio-1
  aspect-ratio: 1
